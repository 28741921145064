<template>
  <MyAccountCheckAuthLoggedIn>
    <MyAccountProfileEdit />
  </MyAccountCheckAuthLoggedIn>
</template>

<script setup lang="ts">
const { url } = useDomainHost()

const { t } = useI18n()

useHead({
  title: t('profile'),
  link: [useSeoCanonical(url + '/my-account/edit')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(3, t('edit_profile'), url + '/my-account/edit')
    ])
  ]
})
</script>
